<template>
  <div class="default-transfer-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <h1>{{ title || $t('transfers.add') }}</h1>
      </template>

      <template #default="{ ok }">
        <b-form ref="form" @submit.prevent="ok(record)">
          <!-- shops -->
          <ul class="list-group m-t-md"
              v-for="(list, shopKey) in { start_shop: startShopInputList, end_shop: endShopInputList, ...(showCostCentreShop ? {cost_centre_shop: costCentreShopInputList} : {}) }">
            <li class="list-group-item">
              <h4>{{ $t("common." + shopKey.replace('_shop', '')) }}</h4>
            </li>

            <li v-show="!$data[shopKey + 'Minimized']" class="list-group-item"
                v-if="list.find(row => row.find(input => input.visible===undefined || $compute(input.visible)))">
              <b-row align-v="center" v-for="row in list">
                <b-col v-bind="$compute(input.col)" v-for="input in row"
                       v-if="input.visible===undefined || $compute(input.visible)">
                  <generic-input v-bind="input" :value="$g(record, input.key)"
                                 @input="(event) => $s(record, input.key, event)"/>
                </b-col>
              </b-row>
            </li>
            <li v-if="record[shopKey + '_id']<=-3" v-show="!$data[shopKey + 'Minimized']"
                class="list-group-item">
              <b-row align-v="center">
                <b-col>
                  {{ $t('common.opening_hours') }}
                  <p v-if="record[shopKey + '_id']<=-1 && !$data[shopKey+'TimeVisible']">
                    ({{ $t('opening_hours.default') }})</p>
                </b-col>
                <b-col class="text-center">
                  <b-button
                      v-if="!$data[shopKey + 'TimeVisible']"
                      variant="default"
                      style="background-color: transparent"
                      @click.prevent="$data[shopKey+'TimeVisible'] = true">
                    {{ $t('button.edit') }}
                  </b-button>
                </b-col>
                <b-col cols="12" v-if="$data[shopKey+'TimeVisible']" class="m-t-sm">
                  <opening-hours v-model="record[shopKey].opening_hours"/>
                </b-col>
              </b-row>
            </li>
            <li v-if="record[shopKey + '_id']<=-3" v-show="!$data[shopKey + 'Minimized']"
                class="list-group-item">
              <metadata-edit-list v-model="record[shopKey].metadata"
                                  label="multiples.metadata.one"/>
            </li>
          </ul>

          <!-- general -->
          <ul class="list-group m-t-md">
            <li class="list-group-item">
              <h4>{{ $t("common.general") }}</h4>
            </li>
            <li class="list-group-item" v-for="list in generalInputList"
                v-if="list.find(input => input.visible===undefined || $compute(input.visible))">
              <b-row align-v="center">
                <b-col v-for="input in list" v-bind="$compute(input.col)"
                       v-if="input.visible===undefined || $compute(input.visible)">
                  <generic-input v-bind="input" v-model="record[input.key]"/>
                </b-col>
              </b-row>
            </li>
          </ul>

          <!-- vehicles -->
          <ul class="list-group m-t-md">
            <li v-for="(vehicle, index) in record.vehicles" class="list-group-item">
              <b-row>
                <b-col>
                  <h4>
                    {{ $t("multiples.vehicle.one") + " " + (index + 1) }}
                  </h4>
                </b-col>
                <b-col>
                  <b-button v-if="index > 0" @click.prevent="$delete(record.vehicles, index)" variant="no-outline"
                            style="float: right;">
                    <font-awesome-icon icon="times"/>
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-for="list in vehicleInputList">
                <b-col v-for="input in list" v-bind="$compute(input.col)"
                       v-if="input.visible===undefined || $compute(input.visible, vehicle)">
                  <generic-input v-bind="input" v-model="vehicle[input.key]"
                                 @onSelected="input.onSelected || (()=>{})"/>
                </b-col>
              </b-row>
              <b-row @click="vehicle.extendedInput = !vehicle.extendedInput" style="cursor: pointer;">
                <b-col>
                  <h4>{{ $t("transfers.additional_notes") + " (Optional)" }}</h4>
                </b-col>
                <b-col align="center" cols="1">
                  <font-awesome-icon :class="{ 'fa-rotate-180': vehicle.extendedInput }" icon="caret-down"/>
                </b-col>
              </b-row>
              <ul v-show="vehicle.extendedInput" class="list-group m-t-md">
                <li class="list-group-item">
                  <b-row v-for="list in vehicleAdvancedInputList">
                    <b-col v-for="input in list" v-bind="$compute(input.col)"
                           v-if="input.visible===undefined || $compute(input.visible, vehicle)">
                      <generic-input v-bind="input" v-model="vehicle[input.key]"
                                     @onSelected="input.onSelected || (()=>{})"/>
                    </b-col>
                  </b-row>
                  <metadata-edit-list class="m-t-sm m-b-sm" v-model="vehicle.metadata"
                                      label="multiples.metadata.one"/>
                </li>
              </ul>
            </li>
          </ul>

          <b-row v-if="checkAllowedTypes(['create']) && record.vehicles.length < 10"
                 class="justify-content-center m-t-lg">
            <b-button @click.prevent="record.vehicles.push(vehicleDefaultState())"
                      variant="default">
              {{ $t("vehicles.add") }}
            </b-button>
          </b-row>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm" variant="default" @click="cancel">{{ $t('button.back') }}</b-button>
          <b-button size="sm" variant="primary" type="submit" @click.prevent="$refs.form.requestSubmit()"
                    :title="saveButtonTitle">
            {{ $t('button.save') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {getTransfer, postTransfer, putTransfer, restoreTransfer} from "@/services/transfers";
import {getSelectableShops, getSelectableRoles, getShop} from "@/services/shops";
import {getSelectableVehicleClasses} from "@/services/vehicle_classes";
import {
  getSelectableBrands,
  getSelectableEngines, getSelectableRims, getSelectableTires,
  getSelectableTransmissions, getSelectableTreads
} from "@/services/vehicles";
import SearchableSelect from "@/components/searchable_select";
import Datepicker from "@/components/datepicker";
import GenericInput from "@/components/generic_input";
import {mapGetters} from "vuex";
import MetadataEditList from "@/components/metadata_edit_list";
import OpeningHours from "@/components/opening_hours";
import {getSelectableClients} from "@/services/clients";

export default {
  name: "DefaultTransferModal",
  components: {OpeningHours, MetadataEditList, GenericInput, Datepicker, SearchableSelect},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
    type: {
      type: String,
      default: 'create'
    },
    title: {
      type: String,
      default: ''
    },
    showDeliveryType: {
      type: [Boolean, Function],
      default: true
    },
    showPrice: {
      type: [Boolean, Function],
      default: false
    },
    showDeputyClient: {
      type: [Boolean, Function],
      default: false
    },
    showCostCentreShop: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {
      visible: false,
      start_shopMinimized: false,
      end_shopMinimized: false,
      cost_centre_shopMinimized: false,
      start_shopTimeVisible: false,
      end_shopTimeVisible: false,
      cost_centre_shopTimeVisible: false,
      record: null, // current user not present on modal creation, setting to default makes no sense
      selectableCache: {
        shops: [],
        shopRoles: [],
        rentalClients: [],
        vehicleClasses: [],
        vehicles: [],
        vehicleBrands: [],
        vehicleModels: [],
        vehicleEngines: [],
        vehicleTransmissions: []
      },
      startShopInputList: this.shopConfigList('start_shop'),
      endShopInputList: this.shopConfigList('end_shop'),
      costCentreShopInputList: this.shopConfigList('cost_centre_shop'),
      generalInputList: [
        [
          {
            key: 'deputy_client_id',
            label: 'multiples.principal.one',
            placeholder: 'selector.select_principal',
            type: 'select',
            fetchRecords: () => this.selectableCache.rentalClients,
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create']),
            visible: () => this.showDeputyClient && (this.checkAllowedTypes(['create']) || this.record.deputy_client_id > 0),
            required: true
          }
        ],
        [
          {
            key: 'start_date',
            label: ['common.pickup_date_day', 'common.pickup_date_time'],
            type: 'datepicker',
            disabled: () => !this.checkAllowedTypes(['create', 'edit']),
            required: true
          },
          {
            key: 'end_date',
            label: ['common.delivery_date_day', 'common.delivery_date_time'],
            type: 'datepicker',
            disabled: () => !this.checkAllowedTypes(['create', 'edit', 'restore']),
            required: true
          }
        ],
        [
          {
            key: 'mediation_via_ugoki',
            label: 'common.mediation_via_ugoki',
            type: 'checkbox',
            disabled: () => this.isUgokiCheckboxDisabled,
            visible: () => this.showDeliveryType
          },
          {
            key: 'mediation_via_prologistics',
            label: 'common.mediation_via_prologistics',
            type: 'checkbox',
            disabled: () => this.isPrologisticsCheckboxDisabled,
            visible: () => this.showDeliveryType
          },
          {
            key: 'mediation_via_trailer',
            label: 'common.mediation_via_trailer',
            type: 'checkbox',
            disabled: () => this.isTrailerCheckboxDisabled,
            visible: () => this.showDeliveryType
          }
        ],
        [
          {
            key: 'latest_reservation_date',
            label: ['common.latest_reservation_date_day', 'common.latest_reservation_date_time'],
            type: 'datepicker',
            disabled: () => !this.checkAllowedTypes(['create', 'edit', 'restore']),
            visible: () => !this.record.mediation_via_prologistics && !this.record.mediation_via_trailer,
            required: true
          }
        ]
      ],
      vehicleInputList: [
        [
          {
            key: 'brand',
            label: 'common.brand',
            placeholder: 'selector.select_brand',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleBrands,
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create']),
            required: true
          },
          {
            key: 'model',
            label: 'common.model',
            placeholder: 'RS e-tron GT',
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create']),
            required: true
          },
        ],
        [
          {
            key: 'engine',
            label: 'common.engine',
            placeholder: 'selector.select_engine',
            type: 'select',
            col: { lg: 6 },
            fetchRecords: () => this.selectableCache.vehicleEngines,
            onSelected: () => null,
            required: true
          }
        ],
        [
          {
            key: 'requires_license_plate',
            label: 'common.requires_license_plate',
            type: 'checkbox',
            onSelected: () => null
          }
        ],
        [
          {
            key: 'vin',
            label: 'common.vin',
            placeholder: 'ZFAEFAC39MX018432',
            onSelected: () => null,
            required: true,
            col: { lg: 6 },
            visible: (vehicle) => vehicle.requires_license_plate === true || vehicle.vin_missing !== true
          },
          {
            key: 'vin_missing',
            label: 'transfers.vin_missing',
            type: 'checkbox',
            onSelected: () => null,
            visible: (vehicle) => vehicle.requires_license_plate !== true && (vehicle.vin === null || vehicle.vin.trim().length === 0)
          }
        ],
        [
          {
            key: 'license_plate',
            label: 'common.license_plate',
            placeholder: 'AAA-BB 123',
            onSelected: () => null,
            required: true,
            col: { lg: 6 },
            visible: (vehicle) => vehicle.requires_license_plate !== true && vehicle.license_plate_missing !== true
          },
          {
            key: 'license_plate_missing',
            label: 'transfers.license_plate_missing',
            type: 'checkbox',
            onSelected: () => null,
            visible: (vehicle) => vehicle.requires_license_plate !== true && (vehicle.license_plate === null || vehicle.license_plate.trim().length === 0)
          }
        ],
        [
          {
            key: 'delivery_in_opening_hours',
            label: 'common.delivery_in_opening_hours',
            type: 'checkbox',
            onSelected: () => null
          }
        ],
        [
          {
            key: 'notes',
            label: 'common.notes',
            placeholder: 'transfers.additional_notes',
            onSelected: () => null
          },
          {
            key: 'price',
            label: 'common.price',
            type: 'currency',
            col: { lg: 4 },
            onSelected: () => null,
            visible: () => this.showPrice
          }
        ]
      ],
      vehicleAdvancedInputList: [
        [
          {
            key: 'external_order_number',
            label: 'common.order_number',
            type: 'text',
            onSelected: () => null
          },
          {
            key: 'external_invoice_number',
            label: 'common.invoice_number',
            type: 'text',
            onSelected: () => null
          }
        ],
        [
          {
            key: 'vehicle_class_id',
            label: 'multiples.vehicle_class.one',
            placeholder: 'selector.select_vehicle_class',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleClasses,
            onSelected: (record) => null,
            disabled: () => !this.checkAllowedTypes(['create'])
          }
        ],
        [
          {
            key: 'transmission',
            label: 'common.transmission',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleTransmissions,
            onSelected: () => null
          }
        ],
        [
          {
            key: 'tire',
            label: 'common.tire',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleTires,
            onSelected: () => null
          },
          {
            key: 'rim',
            label: 'common.rim',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleRims,
            onSelected: () => null
          }
        ],
        [
          {
            key: 'tread',
            label: 'common.tread',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleTreads,
            onSelected: () => null
          },
          {
            key: 'tread_mm',
            label: 'common.tread_mm',
            type: 'number',
            precision: 1,
            min: 0,
            onSelected: () => null
          }
        ],
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value) {
          // build cache
          this.selectableCache.shops = await getSelectableShops({})
          this.selectableCache.shopRoles = await getSelectableRoles({})
          if (this.showDeputyClient) this.selectableCache.rentalClients = await getSelectableClients({option: 'rental'})
          this.selectableCache.vehicleClasses = await getSelectableVehicleClasses({})
          this.selectableCache.vehicleBrands = await getSelectableBrands({})
          this.selectableCache.vehicleEngines = await getSelectableEngines({})
          this.selectableCache.vehicleTransmissions = await getSelectableTransmissions({})
          this.selectableCache.vehicleTires = await getSelectableTires({})
          this.selectableCache.vehicleRims = await getSelectableRims({})
          this.selectableCache.vehicleTreads = await getSelectableTreads({})
          // initialize by given id
          if (this.id > 0) {
            const record = {...(await getTransfer(this.id)), vehicles: []} // TODO: catch
            // copy record values to vehicle to match form setup
            record.vehicle.external_order_number = record.external_order_number
            record.vehicle.external_invoice_number = record.external_invoice_number
            record.vehicle.vehicle_class_id = record.vehicle_class_id
            record.vehicle.engine = record.engine
            record.vehicle.transmission = record.transmission
            record.vehicle.tire = record.tire
            record.vehicle.rim = record.rim
            record.vehicle.tread = record.tread
            record.vehicle.tread_mm = record.tread_mm
            record.vehicle.delivery_in_opening_hours = record.delivery_in_opening_hours
            record.vehicle.requires_license_plate = record.requires_license_plate
            record.vehicle.license_plate = record.license_plate
            record.vehicle.vin = record.vin
            record.vehicle.notes = record.notes
            record.vehicle.price = record.price
            record.vehicle.metadata = record.metadata
            // initialize input visibility flag
            record.vehicle.extendedInput = false
            this.record = record
          } else if (this.record === null || this.record.id > 0) { // initialize by present record id
            this.record = this.recordDefaultState() // initialize by present record id
          }
          // setup for creation
          if (this.checkAllowedTypes("create")) {
            this.record.id = 0
            // disable mediation channel based on client allowance
            if(this.current_user?.client?.mediation_via_ugoki === false) {
              this.record.mediation_via_ugoki = false
            }
            if(this.current_user?.client?.mediation_via_prologistics === false) {
              this.record.mediation_via_prologistics = false
            }
            if(this.current_user?.client?.mediation_via_trailer === false) {
              this.record.mediation_via_trailer = false
            }
            // keeping ids leads to creation errors and makes no sense
            this.record.metadata.forEach(metadata => {
              metadata.id = null
            })
            // keeping ids leads to creation errors and makes no sense
            this.record.vehicle.metadata.forEach(metadata => {
              metadata.id = null
            })
          }
          // push first vehicle into vehicles list to populate modal vehicle list part
          if (this.record.vehicles.length === 0) {
            this.record.vehicles.push(this.record.vehicle)
          }
        }
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    },
    'record.start_shop_id': {
      async handler(value) {
        this.record.start_shop = value > 0 ? await getShop(value, {}) : this.shopDefaultState()
        this.start_shopTimeVisible = value > 0
        // set same as start
        if (value !== 0 && value === this.record.cost_centre_shop_id) this.record.cost_centre_shop_id = -1
        else if (this.record.cost_centre_shop_id === -1) this.record.cost_centre_shop = this.record.start_shop
      }
    },
    'record.end_shop_id': {
      async handler(value) {
        this.record.end_shop = value > 0 ? await getShop(value, {}) : this.shopDefaultState()
        this.end_shopTimeVisible = value > 0
        // set same as end
        if (value !== 0 && value === this.record.cost_centre_shop_id) this.record.cost_centre_shop_id = -2
        else if (this.record.cost_centre_shop_id === -2) this.record.cost_centre_shop = this.record.end_shop
      }
    },
    'record.cost_centre_shop_id': {
      async handler(value) {
        this.$nextTick(async () => {
          this.cost_centre_shopTimeVisible = (value || 0) > 0
          if ((value || 0) === 0) {
            this.record.cost_centre_shop = null
            return
          }
          // select same as start
          if (value === this.record.start_shop_id) {
            this.record.cost_centre_shop_id = -1
            return
          }
          // select same as end
          if (value === this.record.end_shop_id) {
            this.record.cost_centre_shop_id = -2
            return
          }
          // copy start
          if (value === -1) {
            this.record.cost_centre_shop = this.record.start_shop
            return
          }
          // copy end
          if (value === -2) {
            this.record.cost_centre_shop = this.record.end_shop
            return
          }
          // get by value
          this.record.cost_centre_shop = value > 0 ? await getShop(value, {}) : this.shopDefaultState()
        })
      }
    },
    'record.end_date': {
      async handler(value) {
        if (this.record.mediation_via_prologistics || this.record.mediation_via_trailer) this.record.latest_reservation_date = this.record.end_date
      }
    },
    'record.mediation_via_prologistics': {
      async handler(value) {
        if (this.record.mediation_via_prologistics) this.record.latest_reservation_date = this.record.end_date
      }
    },
    'record.mediation_via_trailer': {
      async handler(value) {
        if (this.record.mediation_via_trailer) this.record.latest_reservation_date = this.record.end_date
      }
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    isUgokiCheckboxDisabled() {
      return (this.record.mediation_via_ugoki && !this.record.mediation_via_prologistics && !this.record.mediation_via_trailer) ||
          (!this.current_user.client.mediation_via_ugoki && !this.record.mediation_via_ugoki)
    },
    isPrologisticsCheckboxDisabled() {
      return (!this.record.mediation_via_ugoki && this.record.mediation_via_prologistics && !this.record.mediation_via_trailer) ||
          (!this.current_user.client.mediation_via_prologistics && !this.record.mediation_via_prologistics)
    },
    isTrailerCheckboxDisabled() {
      return (!this.record.mediation_via_ugoki && !this.record.mediation_via_prologistics && this.record.mediation_via_trailer) ||
          (!this.current_user.client.mediation_via_trailer && !this.record.mediation_via_trailer)
    },
    saveButtonTitle() {
      return this.shopsInvalidErrorMessage() || this.datesInvalidErrorMessage() || this.prologisticsInvalidErrorMessage() || this.vehiclesInvalidErrorMessage() || this.deputyClientInvalidErrorMessage()
    }
  },
  methods: {
    shopInvalidErrorMessage(shop) {
      if (typeof shop.street_name !== 'string' || shop.street_name.trim().length === 0) return 'Straßenname nicht ausgefüllt'
      if (typeof shop.postcode !== 'string' || shop.postcode.trim().length === 0) return 'Postleitzahl nicht ausgefüllt'
      if (typeof shop.city.name !== 'string' || shop.city.name.trim().length === 0) return 'Stadtname nicht ausgefüllt'
      if (typeof shop.city.country_name !== 'string' || shop.city.country_name.trim().length === 0) return 'Landname nicht ausgefüllt'
      return null
    },
    shopsInvalidErrorMessage() {
      // start shop persisted and start same as end
      if (this.record.start_shop_id > 0 && this.record.start_shop_id === this.record.end_shop_id) return 'Start und Ziel sind gleich'
      // start shop new and invalid
      if (this.record.start_shop_id === 0) return 'Start nicht ausgewählt'
      if (this.record.start_shop_id <= 0 && this.shopInvalidErrorMessage(this.record.start_shop)) return 'Start ' + this.shopInvalidErrorMessage(this.record.start_shop)
      // end shop new and invalid
      if (this.record.end_shop_id === 0) return 'Ziel nicht ausgewählt'
      if (this.record.end_shop_id <= 0 && this.shopInvalidErrorMessage(this.record.end_shop)) return 'Ziel ' + this.shopInvalidErrorMessage(this.record.end_shop)
      // cost centre new and invalid
      if (this.showCostCentreShop) {
        if (this.record.cost_centre_shop_id === -3 && this.shopInvalidErrorMessage(this.record.cost_centre_shop)) return 'Kostenstelle ' + this.shopInvalidErrorMessage(this.record.cost_centre_shop)
      }
      return null
    },
    datesInvalidErrorMessage() {
      if (this.record.start_date.getTime() > this.record.end_date.getTime()) return 'Startdatum muss kleiner als Enddatum sein'
      if (this.record.end_date.getTime() <= new Date().getTime()) return 'Enddatum muss in der Zukunft liegen'
      return null
    },
    prologisticsInvalidErrorMessage() {
      if (!this.record.mediation_via_prologistics && !this.record.mediation_via_trailer) {
        if (this.record.latest_reservation_date <= new Date().getTime()) return 'Spätestes Vermittlungsdatum muss in der Zukunft liegen'
        if (this.record.latest_reservation_date > this.record.end_date.getTime()) return 'Spätestes Vermittlungsdatum muss vor Enddatum liegen'
      }
      return null
    },
    vehiclesInvalidErrorMessage() {
      let errorMessage = null
      this.record.vehicles.forEach(entry => {
        if (entry.vehicle_class_id < 0) return errorMessage = 'Fahrzeugklasse nicht ausgewählt'
        if (typeof entry.brand !== 'string' || entry.brand.trim().length <= 0) return errorMessage = 'Fahrzeug Marke nicht ausgewählt'
        if (typeof entry.model !== 'string' || entry.model.trim().length <= 0) return errorMessage = 'Fahrzeug Modell nicht ausgewählt'
        if (entry.engine < 0) return errorMessage = 'Fahrzeug Antrieb nicht ausgewählt'
        if (entry.transmission < 0) return errorMessage = 'Fahrzeug Getriebe nicht ausgewählt'
        if (entry.tire < 0) return errorMessage = 'Fahrzeug Reifen nicht ausgewählt'
        if (entry.rim < 0) return errorMessage = 'Fahrzeug Felge nicht ausgewählt'
        if (entry.tread < 0) return errorMessage = 'Fahrzeug Profil nicht ausgewählt'
        if (entry.tread_mm < 0) return errorMessage = 'Fahrzeug Profiltiefe nicht ausgewählt'
      })
      return errorMessage
    },
    deputyClientInvalidErrorMessage() {
      if (this.showDeputyClient && this.record.deputy_client_id <= 0) return 'Stellvertreter nicht ausgewählt'
      return null
    },
    extendedShopSelectList(shopKey) {
      const extendedShops = JSON.parse(JSON.stringify(this.selectableCache?.shops?.results || [])) // do not change reference
      if (shopKey === "cost_centre_shop") {
        extendedShops.unshift({id: -1, text: this.$t('shops.identical_to_start')}, {
          id: -2,
          text: this.$t('shops.identical_to_end')
        }, {id: -3, text: '+ ' + this.$t('shops.add')})
      } else {
        extendedShops.unshift({id: -3, text: '+ ' + this.$t('shops.add')})
      }
      return extendedShops
    },
    shopConfigList(shopKey) {
      return [
        [
          {
            key: shopKey + '_id',
            label: 'multiples.shop.one',
            placeholder: shopKey === 'cost_centre_shop' ? 'shops.no_cost_centre' : 'selector.select_shop',
            type: 'select',
            fetchRecords: () => this.extendedShopSelectList(shopKey),
            disabled: () => !this.checkAllowedTypes(['create']),
            required: shopKey !== 'cost_centre_shop'
          }
        ],
        [
          {
            key: shopKey + '.' + 'street_name',
            label: 'common.street_name',
            placeholder: this.$t('prologistics.street_name'),
            col: { lg: 8 },
            visible: () => this.record[shopKey + "_id"] <= -3,
            disabled: () => this.record[shopKey + "_id"] >= 0,
            required: true
          },
          {
            key: shopKey + '.' + 'postcode',
            label: 'common.postcode',
            placeholder: this.$t('prologistics.postcode'),
            col: { lg: 4 },
            visible: () => this.record[shopKey + "_id"] <= -3,
            disabled: () => this.record[shopKey + "_id"] >= 0,
            required: true
          }
        ],
        [
          {
            key: shopKey + '.' + 'town_name',
            label: 'common.town',
            placeholder: this.$t('prologistics.town_name'),
            visible: () => this.record[shopKey + "_id"] <= -3,
            disabled: () => this.record[shopKey + "_id"] >= 0,
          },
          {
            key: shopKey + '.' + 'city.name',
            label: 'common.city',
            placeholder: this.$t('prologistics.city_name'),
            visible: () => this.record[shopKey + "_id"] <= -3,
            disabled: () => this.record[shopKey + "_id"] >= 0,
            required: true
          },
          {
            key: shopKey + '.' + 'city.country_name',
            label: 'common.country',
            placeholder: this.$t('prologistics.country_name'),
            visible: () => this.record[shopKey + "_id"] <= -3,
            disabled: () => this.record[shopKey + "_id"] >= 0,
            required: true
          }
        ],
        [
          {
            key: shopKey + '.' + 'contact.name',
            label: 'common.name',
            placeholder: 'Max Mustermann',
            col: { lg: 4 },
            visible: () => this.record[shopKey + "_id"] <= -3
          }
        ],
        [
          {
            key: shopKey + '.' + 'contact.email',
            label: 'common.email',
            placeholder: this.$t('prologistics.email'),
            visible: () => this.record[shopKey + "_id"] <= -3
          },
          {
            key: shopKey + '.' + 'contact.phone_number',
            label: 'common.phone_number',
            placeholder: "+49123456789",
            visible: () => this.record[shopKey + "_id"] <= -3
          }
        ],
        [
          {
            key: shopKey + '.' + 'reference_code',
            label: 'common.reference_code',
            placeholder: 'XY456',
            col: { lg: 4 },
            visible: () => this.record[shopKey + "_id"] <= -3
          }
        ],
        [
          {
            key: shopKey + '.' + 'role',
            label: 'common.role',
            col: { lg: 4 },
            type: 'select',
            fetchRecords: () => this.selectableCache.shopRoles,
            visible: () => this.record[shopKey + "_id"] <= -3,
            required: true
          }
        ],
        [
          {
            key: shopKey + '.' + 'contact.id_last_four',
            label: 'common.id_last_four',
            placeholder: 'HKS2',
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          },
          {
            key: shopKey + '.' + 'contact.license_last_four',
            label: 'common.license_last_four',
            placeholder: 'MDS9',
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          },
          {
            key: shopKey + '.' + 'contact.customer_number',
            label: 'common.customer_number',
            placeholder: 'VEH-12345',
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          },
        ],
        [
          {
            key: shopKey + '.' + 'authorizer.name',
            label: 'common.authorizer_name',
            placeholder: 'John Doe',
            col: { lg: 4 },
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          },
        ],
        [
          {
            key: shopKey + '.' + 'authorizer.id_last_four',
            label: 'common.authorizer_id_last_four',
            placeholder: 'KDS3',
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          },
          {
            key: shopKey + '.' + 'authorizer.license_last_four',
            label: 'common.authorizer_license_last_four',
            placeholder: 'MGSD8',
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          },
        ],
        [
          {
            key: shopKey + '.' + 'authorizer.email',
            label: 'common.authorizer_email',
            placeholder: this.$t('prologistics.email'),
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          },
          {
            key: shopKey + '.' + 'authorizer.phone_number',
            label: 'common.authorizer_phone_number',
            placeholder: "+49123456789",
            visible: () => this.record[shopKey + "_id"] <= -3 && this.record[shopKey].role === 3
          }
        ]
      ]
    },
    recordDefaultState() {
      return {
        id: 0,
        deputy_client_id: 0,
        start_shop_id: 0,
        end_shop_id: 0,
        cost_centre_shop_id: null,
        start_shop: this.shopDefaultState(),
        end_shop: this.shopDefaultState(),
        cost_centre_shop: null,
        start_date: new Date(),
        end_date: new Date(),
        latest_reservation_date: new Date(),
        mediation_via_ugoki: this.current_user?.client?.mediation_via_ugoki === true,
        mediation_via_prologistics: this.current_user?.client?.mediation_via_prologistics === true,
        mediation_via_trailer: this.current_user?.client?.mediation_via_trailer === true,
        vehicle: this.vehicleDefaultState(),
        vehicles: [],
        metadata: []
      }
    },
    shopDefaultState() {
      return {
        id: 0,
        street_name: null,
        postcode: null,
        town_name: null,
        contact: {
          name: null,
          id_last_four: null,
          license_last_four: null,
          phone_number: null,
          email: null,
          customer_number: null
        },
        authorizer: {
          name: null,
          id_last_four: null,
          license_last_four: null,
          phone_number: null,
          email: null
        },
        city: {
          name: '',
          country_name: 'Deutschland'
        },
        role: 0,
        reference_code: null,
        metadata: [],
        opening_hours: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].reduce((prev, curr) => {
          prev[curr] = [[new Date("Thu, 01 Jan 1970 08:00:00"), new Date("Thu, 01 Jan 1970 18:00:00")]]
          return prev
        }, {})
      }
    },
    vehicleDefaultState() {
      return {
        id: 0,
        vehicle_class_id: 0,
        engine: -1,
        transmission: 0,
        tire: 0,
        rim: 0,
        tread: 0,
        tread_mm: 0.0,
        delivery_in_opening_hours: true,
        requires_license_plate: false,
        license_plate: null,
        vin: null,
        notes: null,
        price: this.$compute(this.showPrice) ? 0 : -1,
        metadata: [],
        extendedInput: false
      }
    },
    checkAllowedTypes(types) {
      return types.includes(this.type)
    },
    async submitModal() {
      let promise = null;
      if (this.checkAllowedTypes(['create'])) {
        promise = postTransfer(
            {
              transfers: this.record.vehicles.map(vehicle => {
                return {...this.record, ...vehicle, vehicle: vehicle, vehicles: []}
              })
            }
        )
        this.$emit('onCreateRequestResponse', promise)
      } else if (this.checkAllowedTypes(['restore'])) {
        promise = restoreTransfer(this.id, {...this.record, ...this.record.vehicles[0], vehicles: []})
        this.$emit('onRestoreRequestResponse', promise)
      } else {
        promise = putTransfer(this.id, {...this.record, ...this.record.vehicles[0], vehicles: []})
        this.$emit('onEditRequestResponse', promise)
      }
      promise.then((response) => {
        this.record = this.recordDefaultState()
        this.visible = false
      })
      this.$emit('onRequestResponse', promise)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
